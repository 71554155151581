import React from 'react'
import { Builder, withChildren, BuilderBlocks } from '@builder.io/react'
import useGetProduct from '../../hooks/useGetProduct'
import TryItFree from '../../components/try-it-free'
import downloadBlack from '../../images/svgs/download-icon-black.svg'

export default function BuilderTryItFree({ sku, children }) {
    const product = useGetProduct(sku)
    return <TryItFree
        product={product}
        className='h-100 w-100 p-0 border-0 flex-center gap-2'
    >

        {children ?
            <BuilderBlocks blocks={children} />
            :
            <>
                <img src={downloadBlack} alt='' height={22} width={22} />
                DOWNLOAD IT FREE
            </>
        }
    </TryItFree>
}

Builder.registerComponent(withChildren(BuilderTryItFree), {
    name: 'Try It Free',
    inputs: [
        {
            name: 'sku',
            type: 'string',
            required: true,
        }
    ]
})
