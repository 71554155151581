import React from 'react'
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Socials from './socials'
import Year from './year'

import akaiprofessional from '../images/svgs/akai-professional.svg'
import inmusic from '../images/svgs/inmusic-logo-white.svg'

export default function Footer() {
    return (
        <Container fluid className='bg-black border-top border-primary border-2'>
            <Container className='py-5'>
                <Row className='mb-3'>
                    <Col md='auto'>
                        <Link to='/'>
                            <img src={akaiprofessional} alt='' height={24} />
                        </Link>
                    </Col>
                    {/* SOCIAL ICON LINKS */}
                    <Col>
                        <Socials className='my-3 my-md-0 mx-md-4' />
                    </Col>
                    <Col md='auto' className='text-md-end text-white'>
                        <a href="https://inmusicbrands.com" rel='noreferrer' target='_blank'>
                            <img src={inmusic} alt='' />
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-white d-flex flex-column flex-md-row justify-content-between'>
                        <div>
                            <p>© <Year /> inMusic, Inc. All Rights Reserved.</p>
                            <p>
                                <a href="https://www.plugivery.com/about/dealers/" target="_blank" rel="noreferrer" className='text-white'>
                                    Dealers
                                </a>
                                <span>&nbsp; | &nbsp;</span>

                                <a href="https://support.akaipro.com/en/support/home" target="_blank" rel="noreferrer" className='text-white'>
                                    Support
                                </a>
                                <span>&nbsp; | &nbsp;</span>

                                <Link to='/downloads' className='text-white'>
                                    Downloads
                                </Link>
                                <span>&nbsp; | &nbsp;</span>

                                <a href="https://www.inmusicbrands.com/careers/" target="_blank" rel="noreferrer" className='text-white'>
                                    Careers
                                </a>
                                <span>&nbsp; | &nbsp;</span>

                                <a href='https://www.akaipro.com/privacy-policy' target="_blank" rel="noreferrer" className='text-white'>
                                    Privacy
                                </a>
                                <span>&nbsp; | &nbsp;</span>

                                <Link to='/refund-policy' className='text-white'>
                                    Refund Policy
                                </Link>
                                <span>&nbsp; | &nbsp;</span>

                                <Link to='/terms-of-use' className='text-white'>
                                    Terms of Use
                                </Link>
                                <span>&nbsp; | &nbsp;</span>

                                {/* <Link to='/about-us' className='text-white'>
                                    About us
                                </Link>
                                <span>&nbsp; | &nbsp;</span> */}
                                <div id="teconsent" className='d-inline' />
                            </p>
                        </div>
                        <p>Part of the inMusic family of brands</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}