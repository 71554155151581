module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2c08fe9316766a13448b856e90a3e86a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"},{"name":"Poppins","file":"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"},{"name":"Oswald","file":"https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap"},{"name":"Changeling Neo","file":"https://use.typekit.net/wmi5bia.css"},{"name":"Shuttleblock Condensed","file":"https://use.typekit.net/rhp3skp.css"},{"name":"Microgramma Extd D Bold","file":"https://use.typekit.net/mfv0aww.css"},{"name":"Zen Tokyo Zoo","file":"https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo:wght@400&display=swap"},{"name":"Pathway Gothic One","file":"https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K3SC9TR","enableWebVitalsTracking":true,"includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
