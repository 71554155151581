const ls = require('local-storage')
const { BASE_COUNTRY_ISO } = require('../../gatsby-node/constants')

// https://dev.maxmind.com/geoip/geolocate-an-ip/client-side-javascript
module.exports = () => {    
    function onSuccess(response) {
        const event = new CustomEvent('MAXMIND_GEO_IP', { detail: JSON.stringify(response) })
        window.dispatchEvent(event)
    }

    function onError(error) {
        console.log(error)
        const response = { country: { iso_code: BASE_COUNTRY_ISO } }
        const event = new CustomEvent('MAXMIND_GEO_IP', { detail: JSON.stringify(response) })
        window.dispatchEvent(event)
    }

    // This parameter is reserved for future use. 
    // There are no options at this time.
    const options = {}

    const iso = ls.get('SELECTED_COUNTRY_ISO')

    /**
     * use local storage iso if available
     * to cut down on requests to maxmind
     * 
     * if maxmind geoip2 is not available
     * that means we're running inside of builder.io
     * therefore set default iso
     */
    if (iso) {
        onSuccess({ country: { iso_code: iso } })
    } else if (window.geoip2) {
        window.geoip2.country(onSuccess, onError, options)
    } else {
        onSuccess({ country: { iso_code: BASE_COUNTRY_ISO } })
    }

}
