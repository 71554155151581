import { PIMCORE_URL, ENDPOINTS } from "../../gatsby-node/constants"

export default async function applyVoucher(session_cart_id, iso, voucher_code) {
    const options = {
        method: 'post',
        body: JSON.stringify({
            session_cart_id,
            voucher_code
        })
    }

    try {
        const r = await fetch(`${PIMCORE_URL}${ENDPOINTS.REDEEM_VOUCHER(iso)}`, options)
        const d = await r.json()
    
        const { success, message } = d
        if (!success) console.error(message)        
    } catch (err) {
        console.error(err)
    }
}
