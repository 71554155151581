// FRAMEWORK
import React, { useContext } from 'react'
import { Link } from 'gatsby'

// APP
import CountrySelector from './country-selector'
import useGetAllCategory from '../hooks/useGetAllCategory'
import NavSearch from '../algolia/nav-search'
import AppContext from '../context/app'
import Socials from './socials'

// BOOTSTRAP
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Button from 'react-bootstrap/Button'

// SVGS
import closeIconWhite from '../images/svgs/x-close-icon-white.svg'
import newTabIconWhite from '../images/svgs/new-tab-icon-white.svg'

export default function HamburgerOffcanvasNav() {
    const categories = useGetAllCategory()
    const { showNav, setShowNav } = useContext(AppContext)
    const close = () => setShowNav(false)

    return (
        <Navbar.Offcanvas
            placement="end"
            show={showNav}
            onHide={close}
            style={{
                background: `rgba(0, 0, 0, 0.9)`,
                boxShadow: `0px 0px 100px 100px rgba(0,0,0,.9)`,
            }}
        >
            <Offcanvas.Header onHide={close} className='justify-content-between justify-content-md-end align-items-center'>
                {/* <NavSearch className='d-md-none' /> */}
                <Button variant='' onClick={close}>
                    <img src={closeIconWhite} alt='' />
                </Button>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Nav>
                    <p className='text-secondary ff-primary mb-0'>PRODUCTS</p>
                    {
                        categories.map(({ node }) => {
                            const { id, name, slug } = node
                            return (
                                <Nav.Link
                                    key={`nav-menu-link-${id}`}
                                    as={Link}
                                    to={`/${slug}`}
                                    className='ff-primary text-uppercase text-white fw-bold'
                                    style={{ fontSize: 22 }}
                                >
                                    {name}
                                </Nav.Link>
                            )
                        })
                    }
                    <p className='text-secondary ff-primary mb-0 mt-4'>OFFERS</p>
                    <Nav.Link
                        as={Link}
                        to='/sale'
                        className='ff-primary text-uppercase text-white fw-bold'
                        style={{ fontSize: 22 }}
                    >
                        Sale
                    </Nav.Link>
                    {/* <Nav.Link
                        as={Link}
                        to='/collections'
                        className='ff-primary text-uppercase text-white fw-bold'
                        style={{ fontSize: 22 }}
                    >
                        Collections
                    </Nav.Link> */}

                    <p className='text-secondary ff-primary mb-0 mt-4'>LINKS</p>
                    <a
                        href="https://profile.inmusicbrands.com/"
                        target="_blank"
                        rel='noreferrer'
                        style={{ fontSize: 22 }}
                        className='nav-link ff-primary text-uppercase text-white fw-bold d-flex align-items-center gap-2'
                    >
                        My Account
                        <img src={newTabIconWhite} alt='' />
                    </a>
                    <a
                        href="https://support.akaipro.com/en/support/home"
                        target="_blank"
                        rel='noreferrer'
                        style={{ fontSize: 22 }}
                        className='nav-link ff-primary text-uppercase text-white fw-bold d-flex align-items-center gap-2'
                    >
                        Support
                        <img src={newTabIconWhite} alt='' />
                    </a>
                    {/* <Link
                        to='/signup'
                        style={{ fontSize: 22 }}
                        className='nav-link ff-primary text-uppercase text-white fw-bold'
                    >
                        Signup
                    </Link> */}
                    <CountrySelector className='mt-4' />
                    <Socials className='my-4' />
                </Nav>
            </Offcanvas.Body>
        </Navbar.Offcanvas>
    )
}
