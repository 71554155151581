// FRAMEWORK
import React, { useContext, useState, useRef, useEffect } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// APP
import AppContext from '../context/app'
import useGetAllCountry from '../hooks/useGetAllCountry'

// BOOTSTRAP
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'

// SVGS
import chevrondowniconwhite from '../images/svgs/chevron-down-icon-white.svg'

export default function CountrySelector({ className }) {
    const maxHeight = 300
    const { country } = useContext(AppContext)
    const allCountry = useGetAllCountry()

    const inputRef = useRef(null)
    const [show, setShow] = useState(false)
    const [userInput, setUserInput] = useState('')

    function changeCountry(country) {
        const { node } = country
        const { country_iso_code, currency_iso_code } = node

        const detail = {
            countryIso: country_iso_code,
            currencyIso: currency_iso_code.toUpperCase(),
        }

        const event = new CustomEvent("COUNTRY_CURRENCY_CHANGED", { detail })
        if (typeof window !== 'undefined') window.dispatchEvent(event)
    }

    const toggle = (e) => {
        setShow(!show)
    }

    useEffect(() => {
        if (show) inputRef.current.focus()
        else setUserInput('')
    }, [show])

    return (
        <Dropdown onToggle={toggle} show={show} className={className}>
            <Button
                variant=''
                className='ff-secondary py-1 px-0 fw-bold'
                onClick={toggle}
                style={{ fontSize: 22 }}
            >
                <Stack direction='horizontal' className='align-items-center' gap={1}>
                    <GatsbyImage image={getImage(country?.node?.flagImage)} alt='' />
                    <span className='ff-primary text-uppercase text-white'>{country?.node?.name}</span>
                </Stack>
            </Button>

            <Dropdown.Toggle split variant="" className='border-0 py-1'>
                <img src={chevrondowniconwhite} alt='' />
            </Dropdown.Toggle>

            <Dropdown.Menu className='bg-black rounded-0 border-0 p-0 m-0'>
                <input
                    ref={inputRef}
                    placeholder='Filter countries'
                    className='placeholder-font-family-primary my-2'
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    style={{ fontSize: 22 }}
                />

                <div style={{ maxHeight }} className='overflow-auto'>
                    {allCountry
                        .filter(({ node: { name } }) => name.toLowerCase().includes(userInput.toLowerCase()))
                        .map((country, i) => {
                            const { node } = country
                            const { name, country_iso_code, flagImage } = node
                            const isActive = country_iso_code === country?.country_iso_code

                            return (
                                <Dropdown.Item
                                    key={`country-${country_iso_code}-${i}`}
                                    as={Button}
                                    onClick={() => changeCountry(country)}
                                    className='ff-secondary px-0'
                                    style={{ paddingLeft: 12, paddingRight: 12, }}
                                    title={name}
                                    active={isActive}
                                >
                                    <Stack direction='horizontal' className='align-items-center' gap={1}>
                                        <GatsbyImage image={getImage(flagImage)} alt='' />
                                        <span
                                            style={{ fontSize: 18 }}
                                            className={`ff-primary text-uppercase text-white`}
                                        >
                                            {name}
                                        </span>
                                    </Stack>
                                </Dropdown.Item>
                            )
                        })}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}