import React, { useContext, useState } from 'react'
import { PIMCORE_URL, ENDPOINTS } from '../../gatsby-node/constants'
import { Link, navigate } from 'gatsby'
import AppContext from '../context/app'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import useGetAllProduct from '../hooks/useGetAllProduct'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Carousel from 'react-bootstrap/Carousel'
import Spinner from 'react-bootstrap/Spinner'
import chevronRightBlack from '../images/svgs/chevron-right-black.svg'
import getCartId from '../utilities/get-cart-id'

export default function FreeCheckout(props) {
    const { setShowFreeCheckout } = props
    const value = useContext(AppContext)
    const { cart, cartId, country } = value
    const allProduct = useGetAllProduct()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.target
        const email = form.email.value
        const firstname = form.firstname.value
        const lastname = form.lastname.value

        setLoading(true)
        const r = await fetch(`${PIMCORE_URL}${ENDPOINTS.REGISTER_PRODUCT(country?.node.country_iso_code)}`, {
            method: 'post',
            body: JSON.stringify({
                session_cart_id: cartId,
                customer_detail: {
                    email,
                    firstname,
                    lastname,
                }
            })
        })
        setLoading(false)

        const { success, order_number } = await r.json()
        if (success) {
            await navigate('/registered', {
                state: {
                    email,
                    firstname,
                    lastname,
                    order_number,
                    cart
                }
            })
            await getCartId(value)
            setShowFreeCheckout(false)
        } else {
            setError(order_number)
        }
    }

    return (
        <Container className='pt-3'>
            <Row className='align-items-center'>
                <Col md={6} className='text-center'>
                    <p className='ff-primary text-uppercase fw-bold' style={{ fontSize: 32 }}>
                        Congrats on<br /> your free product{cart?.items.length > 1 ? 's' : ''}:
                    </p>
                    <Carousel
                        controls={cart?.items.length > 1}
                        indicators={cart?.items.length > 1}
                        prevIcon={<img src={chevronRightBlack} alt='' style={{ rotate: '180deg' }} />}
                        nextIcon={<img src={chevronRightBlack} alt='' />}
                    >
                        {cart?.items.map(item => {
                            const { product_id } = item
                            const { node } = allProduct.find(({ node }) => node.productId === product_id)
                            const { product_name, shop_categories, slug, childrenFile, prices } = node
                            return (
                                <Carousel.Item className='position-relative'>
                                    <GatsbyImage
                                        alt=''
                                        image={getImage(childrenFile[0])}
                                        className=''
                                        style={{ width: 500 }}
                                        objectFit='contain'
                                    />
                                    {/* <Carousel.Caption className='position-absolute top-0'>
                                        <Link to={`/${shop_categories[0].slug}/${slug}`} className='text-black'>
                                            <p className='ff-primary text-uppercase fw-bold mb-0 text-white' style={{ fontSize: 24 }}>{product_name}</p>
                                            <p className='text-white'>{shop_categories[0]?.name}</p>
                                        </Link>
                                    </Carousel.Caption> */}
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                </Col>
                <Col>
                    <p className='ff-primary fw-bold' style={{ fontSize: 32 }}>
                        Register with inMusic Profile
                    </p>
                    {error && <p className='text-danger'>{error}</p>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Control required type='text' placeholder='First name' name='firstname' className='mb-2' pattern="[A-Za-z ]{1,32}" />
                        <Form.Control required type='text' placeholder='Last name' name='lastname' className='mb-2' pattern="[A-Za-z ]{1,32}" />
                        <Form.Control required type='email' placeholder='Email address' name='email' className='mb-2' />
                        <Form.Text>Please provide your sign on credentials for your inMusic Profile account so that we can add the product to your profile. If you don't have an inMusic profile account, one will be made for you and you'll get an email detailing how to get access.</Form.Text>
                        <div className='d-flex gap-2 mt-3'>
                            <Button
                                variant=''
                                onClick={() => setShowFreeCheckout(false)}
                                className='text-uppercase ff-primary fw-medium flex-center gap-1 border border-black mb-3 mb-md-0'
                                style={{ fontSize: 18, width: 200 }}
                            >
                                Back to cart
                            </Button>
                            <Button
                                type='submit'
                                className={`text-uppercase ff-primary fw-medium flex-center gap-1 border border-primary bg-primary`}
                                style={{ fontSize: 18, width: 200 }}
                            >
                                {
                                    loading ?
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        :
                                        'Submit'
                                }
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}