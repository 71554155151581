exports.components = {
  "component---src-builder-io-templates-page-js": () => import("./../../../src/builder-io/templates/page.js" /* webpackChunkName: "component---src-builder-io-templates-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-mpc-effects-plugins-multiband-filterbank-js": () => import("./../../../src/pages/mpc-effects-plugins/multiband-filterbank.js" /* webpackChunkName: "component---src-pages-mpc-effects-plugins-multiband-filterbank-js" */),
  "component---src-pages-mpc-effects-plugins-soft-clipper-js": () => import("./../../../src/pages/mpc-effects-plugins/soft-clipper.js" /* webpackChunkName: "component---src-pages-mpc-effects-plugins-soft-clipper-js" */),
  "component---src-pages-mpc-instruments-plugins-sub-factory-js": () => import("./../../../src/pages/mpc-instruments-plugins/sub-factory.js" /* webpackChunkName: "component---src-pages-mpc-instruments-plugins-sub-factory-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-registered-js": () => import("./../../../src/pages/registered.js" /* webpackChunkName: "component---src-pages-registered-js" */),
  "component---src-pages-sale-js": () => import("./../../../src/pages/sale.js" /* webpackChunkName: "component---src-pages-sale-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-plugin-js": () => import("./../../../src/templates/plugin.js" /* webpackChunkName: "component---src-templates-plugin-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

